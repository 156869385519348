import React, { useState } from 'react';
import { useFormDataStore } from '../../app/store/formDataStore';
import { useUserStore } from '../../app/store/userStore';
import { CustomInput } from '../CustomInput';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { formatCardNumber } from '../../app/helpers/formatCardNumber';
import { checkLuhn } from '../../app/helpers/cardValidateLuhn';
import cls from './TransactionForm.module.scss';

export const CardToAll = () => {
  const [cards] = useUserStore((state) => [state.cards]);
  const cardOptions = cards.map((card) => {
    return { value: card.number, label: card.title };
  });
  const [
    amount,
    setAmount,
    cardTo,
    setCardTo,
    cardCountryTo,
    withdrawalCurrency
  ] = useFormDataStore((state) => [
    state.amount,
    state.setAmount,
    state.cardTo,
    state.setCardTo,
    state.cardCountryTo,
    state.withdrawalCurrency
  ]);
  const [cardValid, setCardValid] = useState(true);

  const changeCardFromKg = (value: any) => {
    setCardTo(value.value);
  };

  const changeCardFromRu = (value: string) => {
    setCardValid(checkLuhn(value));
    setCardTo(formatCardNumber(value));
  };

  const valueSelectCard = cardOptions.find((item) => item.value === cardTo);

  return (
    <div className={cls.cardUi}>
      {cardCountryTo.value === 'fkb_rf' ? (
        <CustomInput
          value={cardTo}
          onChange={changeCardFromRu}
          closeBtn={true}
          placeholder={'Номер карты получателя'}
          fullWidth={true}
          error={!cardValid}
        />
      ) : (
        <CustomSelect
          value={valueSelectCard}
          onChange={changeCardFromKg}
          options={cardOptions}
          placeholder={'Выберите карту'}
        />
      )}
      <CustomInput
        value={amount}
        onChange={setAmount}
        placeholder={'Сумма перевода'}
        fullWidth={true}
        currency={withdrawalCurrency}
      />
    </div>
  );
};
