import React from 'react';
import cls from './LoaderSpin.module.scss';

export const LoaderSpin = () => {
  return (
    <div className={cls.container}>
      <div className={cls.loader}></div>
    </div>
  );
};
