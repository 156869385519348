export const checkLuhn = (num) =>
  !(
    num
      .replace(/\D/g, '') //remove non-digits
      .split('') //make an array
      .reverse() //last digit first
      .map((e, i) => e * ((i % 2) + 1)) //double every even element
      .join('') //instead of if(d > 9)
      .split('') // d -=9
      .reduce((e, t) => t - 0 + e, 0) % //sum elements
    10
  ); // 0 is falsy and !0 is truey
