import React from 'react';
import { WidthLimiter } from '../UI/WidthLimiter';
import logo from '../../assets/logo.svg';
import { UserBlock } from '../UserBlock';
import { useUserStore } from '../../app/store/userStore';
import { Link } from 'react-router-dom';
import { Paths } from '../../app/providers/Router/paths';
import cls from './Header.module.scss';

export const Header = () => {
  const [person, logout] = useUserStore((state) => [
    state.person,
    state.logout
  ]);

  return (
    <header className={cls.header}>
      <WidthLimiter>
        <div className={cls.container}>
          <Link to={Paths.APP} className={cls.logo}>
            <img src={logo} alt="logo" />
          </Link>
          {person.name && <UserBlock userId={person.name} logout={logout} />}
        </div>
      </WidthLimiter>
    </header>
  );
};
