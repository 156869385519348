import React, { useEffect, useState } from 'react';
import { subscribe } from '@supermegapay/common';
import { Navigate, useSearchParams } from 'react-router-dom';
import { PageTitle } from '../../components/UI/PageTitle';
import { FormWrapper } from '../../components/UI/FormWrapper';
import { CustomButton } from '../../components/CustomButton';
import { PageWrapper } from '../../components/UI/PageWrapper';
import payOk from '../../assets/payOk.svg';
import payWrong from '../../assets/payWrong.svg';
import payWait from '../../assets/payWait.svg';
import cls from './resultPage.module.scss';

const defaultFields = {
  title: 'Получение статуса',
  style: 'pending',
  order: '',
  amount: '',
  currency: 'RUB'
};

export const ResultPage2 = () => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('user_ref');
  const [resultData, setResultData] = useState(defaultFields);
  const { title, style, order, amount, currency } = resultData;
  const [image, setImage] = useState(payWait);
  const [styleText, setStyleText] = useState();

  useEffect(() => {
    switch (style) {
      case 'success':
        setImage(payOk);
        break;
      case 'warning':
        setImage(payWrong);
        break;
      default:
        setImage(payWait);
    }
    setStyleText(cls[style]);
  }, [style]);

  useEffect(() => {
    uuid && subscribe(setResultData, uuid);
  }, [uuid]);

  const handleGoMain = () => {
    window.close();
  };

  if (!uuid) {
    return <Navigate to={'/'} />;
  }

  return (
    <>
      <PageWrapper>
        <PageTitle>Перевод с карты на карту</PageTitle>
        <FormWrapper>
          <form className={cls.form}>
            <img src={image} alt="icon" />
            <h3 className={styleText}>{title}</h3>
            {order && (
              <ul className={cls.list}>
                <li>
                  <p className={cls.label}>Сумма платежа</p>
                  <p className={cls.value}>
                    {amount} {currency}
                  </p>
                </li>
                <li>
                  <p className={cls.label}>Код операции</p>
                  <p className={cls.value}>{order}</p>
                </li>
              </ul>
            )}
            <CustomButton onClick={handleGoMain}>Закрыть</CustomButton>
          </form>
        </FormWrapper>
      </PageWrapper>
    </>
  );
};
