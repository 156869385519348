import React, { useEffect } from 'react';
import { useFormDataStore } from '../../app/store/formDataStore';
import { useUserStore } from '../../app/store/userStore';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import cls from './TransactionForm.module.scss';

export const CardFromKg = () => {
  const [cards, name] = useUserStore((state) => [
    state.cards,
    state.person.name
  ]);
  const cardOptions = cards.map((card) => {
    return { value: card.number, label: card.title };
  });
  const [cardFrom, setCardFrom, setFromName, setFromDate, setFromCVC] =
    useFormDataStore((state) => [
      state.cardFrom,
      state.setCardFrom,
      state.setFromName,
      state.setFromDate,
      state.setFromCVC
    ]);

  const changeCardFrom = (value: any) => {
    setCardFrom(value.value);
  };

  const valueSelectCard = cardOptions.find((item) => item.value === cardFrom);

  useEffect(() => {
    setFromName(name);
  }, []);

  useEffect(() => {
    const findCard = cards.find((item) => item.number === cardFrom);
    if (findCard) {
      setFromDate(`${findCard.mm}/${findCard.yy}`);
      setFromCVC(findCard.cvc);
    }
  }, [cardFrom]);

  return (
    <div className={cls.cardUi}>
      <CustomSelect
        value={valueSelectCard}
        onChange={changeCardFrom}
        options={cardOptions}
        placeholder={'Выберите карту'}
      />
    </div>
  );
};
