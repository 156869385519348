import React from 'react';
import { createRoot } from 'react-dom/client';
import './app/styles/index.scss';
import { RouterProvider } from 'react-router-dom';
import { router } from './app/providers/Router/AppRouter';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
