import React from 'react';
import avatar from '../../assets/avatar.png';
import cls from './UserBlock.module.scss';

export const UserBlock = ({
  userId,
  logout
}: {
  userId: string;
  logout: () => void;
}) => {
  return (
    <div className={cls.container}>
      <div className={cls.user}>
        <img src={avatar} alt="avatar" />
        <h4>{userId}</h4>
      </div>
      <button className={cls.exit} onClick={logout}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path d="M19 10L15 14L15 6L19 10Z" fill="#B4B7BE" />
          <path
            d="M12.5 5.5V2.5L2.5 2.5L2.5 17.5H12.5V14.5"
            stroke="#B4B7BE"
            strokeWidth="1.5"
          />
          <path d="M7.5 10H16" stroke="#B4B7BE" strokeWidth="1.5" />
        </svg>
      </button>
    </div>
  );
};
