import React, { useEffect } from 'react';
import { useUserStore } from '../../app/store/userStore';
import { PageTitle } from '../../components/UI/PageTitle';
import { PageWrapper } from '../../components/UI/PageWrapper';
import { TransactionForm } from '../../components/TransactionForm';

export const CardsPage = () => {
  const [isLoading, cards, getUserData] = useUserStore((state) => [
    state.isLoading,
    state.cards,
    state.getUserData
  ]);

  useEffect(() => {
    getUserData();
  }, []);

  if (isLoading) {
    return (
      <PageWrapper>
        <PageTitle>Загрузка...</PageTitle>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <TransactionForm />
    </PageWrapper>
  );
};
