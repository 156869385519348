import { createBrowserRouter } from 'react-router-dom';
import { Paths } from './paths';
import App from '../../../App';
import { CardsPage } from '../../../pages/cardsPage';
import { DetailsPage } from '../../../pages/detailsPage';
import { ResultPage } from '../../../pages/resultPage';
import { ResultPage2 } from '../../../pages/resultPage';
import { LoginPage } from '../../../pages/loginPage';

export const router = createBrowserRouter([
  {
    path: Paths.APP,
    element: <App />,
    children: [
      {
        path: Paths.APP,
        element: <CardsPage />
      },
      {
        path: Paths.LOGIN,
        element: <LoginPage />
      },
      {
        path: Paths.DETAILS,
        element: <DetailsPage />
      },
      {
        path: Paths.RESULT,
        element: <ResultPage2 />
      }
    ]
  }
]);
