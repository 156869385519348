import React, { ReactNode } from 'react';
import cls from './CustomButton.module.scss';
import { LoaderSpin } from '../UI/LoaderSpin';

interface Props {
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: () => void;
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const CustomButton = ({
  type,
  fullWidth,
  children,
  loading,
  ...props
}: Props) => {
  return (
    <button
      type={type}
      className={cls.container}
      style={fullWidth ? { width: '100%' } : {}}
      {...props}
    >
      {loading ? <LoaderSpin /> : children}
    </button>
  );
};
