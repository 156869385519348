import React from 'react';
import cls from './SummaryBlock.module.scss';

interface Props {
  amount: string;
  rate: number;
  commission: number;
  summary: string;
  withdrawalCurrency: string;
  commissionAmount: string;
  depositAmount: string;
  depositCurrency: string;
}

export const SummaryBlock = ({
  summary,
  amount,
  rate,
  commission,
  withdrawalCurrency,
  commissionAmount,
  depositAmount,
  depositCurrency
}: Props) => {
  return (
    <div className={cls.container}>
      <p>
        Переводим: {amount ? amount : '-'} {withdrawalCurrency}
      </p>

      <p>
        Комиссия: {commission}% ({commissionAmount ? commissionAmount : '-'}{' '}
        {withdrawalCurrency})
      </p>
      <p>
        Итого к списанию: {summary ? summary : '-'} {withdrawalCurrency}
      </p>
      <p>Курс: 1 RUB = {rate} KGS</p>
      <p>
        Итого к зачислению: {depositAmount ? depositAmount : '-'}{' '}
        {depositCurrency}
      </p>
    </div>
  );
};
