import React, { useState } from 'react';
import { useUserStore } from '../../app/store/userStore';
import { PageTitle } from '../../components/UI/PageTitle';
import { FormWrapper } from '../../components/UI/FormWrapper';
import { PageWrapper } from '../../components/UI/PageWrapper';
import { CustomButton } from '../../components/CustomButton';
import { CustomInput } from '../../components/CustomInput';
import cls from './loginPage.module.scss';
import { Navigate } from 'react-router';
import { Paths } from '../../app/providers/Router/paths';

export const LoginPage = () => {
  const [loginFn, id] = useUserStore((state) => [state.login, state.id]);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const changeLogin = (value: string) => {
    setLogin(value);
  };
  const changePassword = (value: string) => {
    setPassword(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    loginFn(login, password);
  };

  if (id) {
    return <Navigate to={Paths.APP} />;
  }

  return (
    <PageWrapper>
      <PageTitle>Вход</PageTitle>
      <FormWrapper>
        <form onSubmit={handleSubmit} className={cls.form}>
          <CustomInput
            value={login}
            onChange={changeLogin}
            placeholder={'Логин или email'}
          />
          <CustomInput
            type={'password'}
            value={password}
            onChange={changePassword}
            placeholder={'Пароль'}
          />
          <CustomButton type="submit" loading={false} disabled={false}>
            Войти
          </CustomButton>
        </form>
      </FormWrapper>
    </PageWrapper>
  );
};
