import './app/styles/index.scss';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { LoaderLayout } from './components/UI/LoaderLayout/LoaderLayout';
import { Outlet } from 'react-router-dom';
import { WidthLimiter } from './components/UI/WidthLimiter';
import { useUserStore } from './app/store/userStore';
import { useEffect } from 'react';

function App() {
  const getUserData = useUserStore((state) => state.getUserData);

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="app-content">
        <WidthLimiter>
          <LoaderLayout>
            <Outlet />
          </LoaderLayout>
        </WidthLimiter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
