import React, { FC, ReactNode, Suspense } from 'react';
import { LoaderSpin } from '../LoaderSpin';
import cls from './LoaderLayout.module.scss';

interface ILoaderLayout {
  children: ReactNode;
}

export const LoaderLayout: FC<ILoaderLayout> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className={cls.loaderLayout}>
          <LoaderSpin />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
