import React from 'react';
import cls from './detailsPage.module.scss';
import { PageTitle } from '../../components/UI/PageTitle';
import { FormWrapper } from '../../components/UI/FormWrapper';
import { PageWrapper } from '../../components/UI/PageWrapper';
import { CustomButton } from '../../components/CustomButton';
import { useFormDataStore } from '../../app/store/formDataStore';

export const DetailsPage = () => {
  const [
    fromName,
    cardFrom,
    amount,
    commission,
    commissionAmount,
    withdrawalCurrency,
    cardTo,
    depositAmount,
    depositCurrency,
    rate,
    uuid,
    sendForm,
    summary
  ] = useFormDataStore((state) => [
    state.fromName,
    state.cardFrom,
    state.amount,
    state.commission,
    state.commissionAmount,
    state.withdrawalCurrency,
    state.cardTo,
    state.depositAmount,
    state.depositCurrency,
    state.rate,
    state.uuid,
    state.sendForm,
    state.summary
  ]);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    sendForm();
  };

  return (
    <PageWrapper>
      <PageTitle>Перевод с карты на карту</PageTitle>
      <FormWrapper>
        <form onSubmit={handleSubmit} className={cls.form}>
          <h3>Проверьте детали операции</h3>
          <ul className={cls.list}>
            <li>
              <p className={cls.label}>ФИО отправителя</p>
              <p className={cls.value}>{fromName}</p>
            </li>
            <li>
              <p className={cls.label}>Номер карты отправителя</p>
              <p className={cls.value}>{cardFrom}</p>
            </li>
            <li>
              <p className={cls.label}>Будет списано у отправителя</p>
              <p className={cls.value}>
                {summary} {withdrawalCurrency}
              </p>
            </li>
            <li>
              <p className={cls.label}>Комиссия</p>
              <p className={cls.value}>
                {commission}% ({commissionAmount} {withdrawalCurrency})
              </p>
            </li>
            <li>
              <p className={cls.label}>Банк получателя</p>
              <p className={cls.value}>
                {depositCurrency === 'RUB'
                  ? 'Тестовый банк'
                  : 'ОАО "ФинансКредитБанк"'}
              </p>
            </li>
            <li>
              <p className={cls.label}>Номер карты получателя</p>
              <p className={cls.value}>{cardTo}</p>
            </li>
            <li>
              <p className={cls.label}>Будет зачислено получателю</p>
              <p className={cls.value}>
                {depositAmount} {depositCurrency}
              </p>
            </li>
            <li>
              <p className={cls.label}>Курс</p>
              <p className={cls.value}>1 RUB = {rate} KGS</p>
            </li>
            <li>
              <p className={cls.label}>Банк партнер</p>
              <p className={cls.value}>
                {depositCurrency === 'RUB'
                  ? 'ОАО "ФинансКредитБанк"'
                  : 'Тестовый банк'}
              </p>
            </li>
            <li>
              <p className={cls.label}>Код операции</p>
              <p className={cls.value}>{uuid}</p>
            </li>
          </ul>
          <CustomButton type="submit">
            К оплате {summary} {withdrawalCurrency}
          </CustomButton>
        </form>
      </FormWrapper>
    </PageWrapper>
  );
};
