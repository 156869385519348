import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import axios from 'axios';
import { router } from '../providers/Router/AppRouter';
import { Paths } from '../providers/Router/paths';

export interface ICard {
  number: string;
  title: string;
  cvc: string;
  mm: number;
  yy: number;
}

type State = {
  id: string;
  person: { name: string };
  cards: ICard[] | [];
  errorMessage: string;
  isLoading: boolean;
};

type Actions = {
  login: (login: string, password: string) => void;
  getUserData: () => void;
  logout: () => void;
};

const initialState: State = {
  id: '',
  person: { name: '' },
  cards: [],
  errorMessage: '',
  isLoading: false
};

export const useUserStore = create<State & Actions>()(
  devtools(
    (set, get) => ({
      ...initialState,
      login: async (login, password) => {
        set({ errorMessage: '' });
        try {
          const response = await axios.post(
            'https://api.p2p.fkb.kg/api/session',
            {
              login,
              password
            },
            {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
              }
            }
          );

          if (response.status === 200) {
            get().getUserData();

            await router.navigate(Paths.APP);
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            set({ errorMessage: e.response?.data });
          } else {
            console.log(e);
            set({ errorMessage: 'Произлошла ошибка. Попробуйте позже.' });
          }
        }
      },
      getUserData: async () => {
        set({ errorMessage: '', isLoading: true });

        try {
          const { data } = await axios.get(
            'https://api.p2p.fkb.kg/api/session',
            {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
              }
            }
          );

          set({ id: data.id, person: data.person, cards: data.cards });
        } catch (e) {
          await router.navigate(Paths.LOGIN);
          if (axios.isAxiosError(e)) {
            set({ errorMessage: e.response?.data });
          } else {
            console.log(e);
            set({ errorMessage: 'Произлошла ошибка. Попробуйте позже.' });
          }
        } finally {
          set({ isLoading: false });
        }
      },
      logout: async () => {
        try {
          const response = await axios.delete(
            'https://api.p2p.fkb.kg/api/session',
            {
              withCredentials: true,
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }
          );

          if (response.status === 204) {
            set(initialState);
            await router.navigate(Paths.LOGIN);
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            set({ errorMessage: e.response?.data });
          } else {
            console.log(e);
            set({ errorMessage: 'Произлошла ошибка. Попробуйте позже.' });
          }
        }
      }
    }),
    { name: 'userStore' }
  )
);
