import React, { useEffect } from 'react';
import { useFormDataStore } from '../../app/store/formDataStore';
import { Paths } from '../../app/providers/Router/paths';
import { PageTitle } from '../UI/PageTitle';
import { FormWrapper } from '../UI/FormWrapper';
import { SummaryBlock } from '../SummaryBlock';
import { CustomButton } from '../CustomButton';
import { useNavigate } from 'react-router';
import { FormFrom } from './FormFrom';
import { FormTo } from './FormTo';
import cls from './TransactionForm.module.scss';

export const TransactionForm = () => {
  const navigate = useNavigate();
  const [
    getRate,
    amount,
    rate,
    withdrawalCurrency,
    commissionAmount,
    commission,
    summary,
    setUuid,
    depositAmount,
    depositCurrency
  ] = useFormDataStore((state) => [
    state.getRate,
    state.amount,
    state.rate,
    state.withdrawalCurrency,
    state.commissionAmount,
    state.commission,
    state.summary,
    state.setUuid,
    state.depositAmount,
    state.depositCurrency
  ]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setUuid();
    navigate(Paths.DETAILS);
  };

  useEffect(() => {
    getRate();
    const getRateInterval = setInterval(() => {
      getRate();
    }, 60000);

    return () => clearInterval(getRateInterval);
  }, []);

  return (
    <>
      <PageTitle>Перевод с карты на карту</PageTitle>
      <FormWrapper>
        <form onSubmit={handleSubmit} className={cls.form}>
          <div className={cls.cards}>
            <FormFrom />
            <FormTo />
          </div>
          <SummaryBlock
            amount={amount}
            rate={rate}
            commission={commission}
            commissionAmount={commissionAmount}
            withdrawalCurrency={withdrawalCurrency}
            summary={summary}
            depositAmount={depositAmount}
            depositCurrency={depositCurrency}
          />
          <CustomButton type="submit">Перевести</CustomButton>
        </form>
      </FormWrapper>
    </>
  );
};
