import React from 'react';
import cls from './CustomInput.module.scss';
import { ReactComponent as IconClose } from '../../assets/close.svg';

interface Props {
  closeBtn?: boolean;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  fullWidth?: boolean;
  maxLength?: number;
  type?: string;
  currency?: string;
  error?: boolean;
}

export const CustomInput = ({
  closeBtn,
  value,
  onChange,
  fullWidth,
  currency,
  error,
  ...props
}: Props) => {
  const inputStyle = error ? `${cls.input} ${cls.error}` : cls.input;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleRemoveValue = () => {
    onChange('');
  };

  return (
    <div className={cls.container} style={fullWidth ? { width: '100%' } : {}}>
      <input
        type="text"
        className={inputStyle}
        value={value}
        onChange={handleChange}
        autoComplete={'new-password'}
        required
        {...props}
      />
      {closeBtn && value && (
        <button className={cls.closeBtn} onClick={handleRemoveValue}>
          <IconClose />
        </button>
      )}
      {currency && <div className={cls.closeBtn}>{currency}</div>}
    </div>
  );
};
