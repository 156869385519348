import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const countryOptions = [
  { value: 'fkb_kr', label: 'Карта КР' },
  { value: 'fkb_rf', label: 'Карта РФ' }
];

type State = {
  cardFrom: string;
  fromDate: string;
  fromCVC: string;
  fromName: string;
  cardTo: string;
  amount: string;
  cardCountryFrom: {
    value: string;
    label: string;
  };
  cardCountryTo: {
    value: string;
    label: string;
  };
  rate: number;
  withdrawalCurrency: string;
  depositCurrency: string;
  commission: number;
  commissionAmount: string;
  summary: string;
  uuid: string;
  depositAmount: string;
};

type Actions = {
  setCardFrom: (value: string) => void;
  setFromDate: (value: string) => void;
  setFromCVC: (value: string) => void;
  setFromName: (value: string) => void;
  setCardTo: (value: string) => void;
  setAmount: (value: string) => void;
  setWithdrawalCurrency: (value: string) => void;
  setCardCountryFrom: (value: any) => void;
  setCardCountryTo: (value: any) => void;
  sendForm: () => void;
  getRate: () => void;
  setUuid: () => void;
};

const initialState: State = {
  cardFrom: '',
  fromDate: '',
  fromCVC: '',
  fromName: '',
  cardTo: '',
  amount: '',
  cardCountryFrom: countryOptions[0],
  cardCountryTo: countryOptions[1],
  rate: 0,
  withdrawalCurrency: 'KGS',
  depositCurrency: 'RUB',
  commission: 2,
  commissionAmount: '',
  summary: '',
  uuid: '',
  depositAmount: ''
};

export const useFormDataStore = create<State & Actions>()(
  devtools(
    (set, get) => ({
      ...initialState,
      setCardFrom: (value) => {
        set({ cardFrom: value });
      },
      setFromDate: (value) => {
        set({ fromDate: value });
      },
      setFromCVC: (value) => {
        set({ fromCVC: value });
      },
      setFromName: (value) => {
        set({ fromName: value });
      },
      setCardTo: (value) => {
        set({ cardTo: value });
      },
      setAmount: (value) => {
        const { rate, commission } = get();
        const commAm = (Number(value) * (commission / 100)).toFixed(2);
        let dep;
        if (get().withdrawalCurrency === 'RUB') {
          dep = (Number(value) * rate).toFixed(2);
        } else {
          dep = (Number(value) * (1 / rate)).toFixed(2);
        }
        set({
          amount: value,
          commissionAmount: commAm,
          summary: (Number(value) + Number(commAm)).toFixed(2),
          depositAmount: dep
        });
      },
      setWithdrawalCurrency: (value) => {
        set({ withdrawalCurrency: value });
      },
      setUuid: () => {
        set({ uuid: uuidv4() });
      },
      setCardCountryFrom: (value) => {
        if (value.value === 'fkb_rf') {
          set({
            cardCountryFrom: countryOptions[1],
            cardCountryTo: countryOptions[0],
            withdrawalCurrency: 'RUB',
            depositCurrency: 'KGS'
          });
        } else {
          set({
            cardCountryFrom: countryOptions[0],
            cardCountryTo: countryOptions[1],
            withdrawalCurrency: 'KGS',
            depositCurrency: 'RUB'
          });
        }
        set({ cardCountryFrom: value });
      },
      setCardCountryTo: (value) => {
        if (value.value === 'fkb_rf') {
          set({
            cardCountryTo: countryOptions[1],
            cardCountryFrom: countryOptions[0],
            withdrawalCurrency: 'RUB',
            depositCurrency: 'KGS'
          });
        } else {
          set({
            cardCountryTo: countryOptions[0],
            cardCountryFrom: countryOptions[1],
            withdrawalCurrency: 'KGS',
            depositCurrency: 'RUB'
          });
        }
      },
      sendForm: async () => {
        const {
          cardFrom,
          fromDate,
          fromCVC,
          fromName,
          cardTo,
          amount,
          uuid,
          commissionAmount,
          summary,
          cardCountryFrom,
          withdrawalCurrency
        } = get();

        try {
          const { data } = await axios.post(
            `https://api.${process.env.REACT_APP_BASE_URL}/api/orders`,
            {
              fields: {
                ['account.value']: cardTo.replace(/\s/g, ''),
                total: amount
              },
              commission: commissionAmount,
              mmyy: fromDate,
              cvc: fromCVC,
              username: fromName,
              card_number: cardFrom.replace(/\s/g, ''),
              amount: summary,
              currency: withdrawalCurrency,
              user_ref: uuid,
              service_id: cardCountryFrom.value,
              provider: 'genesispay'
            }
          );

          if (data.finish_url) {
            window.location.replace(data.finish_url);
          } else if (data.form) {
            const form2wrap = document.createElement('div');
            form2wrap.innerHTML = data.form;
            document.body.appendChild(form2wrap);
            form2wrap.getElementsByTagName('form')[0].submit();
          }
        } catch (error) {
          console.error(error);
        }
      },
      getRate: async () => {
        try {
          const { data } = await axios.get(
            'https://mpi.ptb.bet/api/kurs_kgssom'
          );

          set({ rate: data.kurs });
        } catch (e) {
          console.error(e);
        }
      }
    }),
    { name: 'form' }
  )
);
