import React from 'react';
import { useFormDataStore } from '../../app/store/formDataStore';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { CardToAll } from './CardToAll';
import cls from './TransactionForm.module.scss';

const options = [
  { value: 'fkb_kr', label: 'Карта КР' },
  { value: 'fkb_rf', label: 'Карта РФ' }
];

export const FormTo = () => {
  const [cardCountryTo, setCardCountryTo] = useFormDataStore((state) => [
    state.cardCountryTo,
    state.setCardCountryTo
  ]);

  const changeCardCountryTo = (value: any) => {
    setCardCountryTo(value);
  };

  return (
    <div className={cls.cardForm}>
      <h3 className={cls.cardTitle}>Карта получателя</h3>
      <CustomSelect
        value={cardCountryTo}
        onChange={changeCardCountryTo}
        options={options}
      />
      <CardToAll />
    </div>
  );
};
