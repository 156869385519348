import React from 'react';
import { useFormDataStore } from '../../app/store/formDataStore';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { CardFromRu } from './CardFromRu';
import { CardFromKg } from './CardFromKg';
import cls from './TransactionForm.module.scss';

const options = [
  { value: 'fkb_kr', label: 'Карта КР' },
  { value: 'fkb_rf', label: 'Карта РФ' }
];

export const FormFrom = () => {
  const [cardCountryFrom, setCardCountryFrom] = useFormDataStore((state) => [
    state.cardCountryFrom,
    state.setCardCountryFrom
  ]);

  const changeCountryFrom = (option: any) => {
    setCardCountryFrom(option);
  };

  return (
    <div className={cls.cardForm}>
      <h3 className={cls.cardTitle}>Ваши карты</h3>
      <CustomSelect
        value={cardCountryFrom}
        onChange={changeCountryFrom}
        options={options}
      />
      {cardCountryFrom.value === 'fkb_rf' ? <CardFromRu /> : <CardFromKg />}
    </div>
  );
};
