import React from 'react';
import { WidthLimiter, WidthLimiterPadding } from '../UI/WidthLimiter';
import cls from './Footer.module.scss';

export const Footer = () => {
  return (
    /*    <footer class="page-footer red darken-3">
      <div class="container">
        <div class="row">
          <div class="col l6 s12">
            <h5 class="white-text">ОАО "ФинансКредитБанк"</h5>
            <p class="grey-text text-lighten-4">
              Наш адрес: г.Бишкек, ул.Абдрахманова 105<br>
              Тел.: (312)30-30-14, 30-35-10, 30-34-87, 30-34-12, 443-443<br>
              Моб., WhatsApp +996 (755) 443-443<br>
              E-mail: fkb@fkb.kg
            </p>
          </div>
          <div class="col l6 s12">
            <h5 class="white-text">Режим работы</h5>
            <ul>
              <li><a class="white-text" href="https://fkb.kg/#!">Понедельник-пятница с 8.30-16.30 ч., без перерыва;</a></li>
              <!-- <li><a class="white-text" href="#!">Суббота - с 9.00 - 15.00 ч., без перерыва;</a></li> -->
              <li><a class="white-text" href="https://fkb.kg/#!">ВЕЧЕРНЯЯ КАССА тел. (312) 443 443 (доб. 183);</a></li>
              <li><a class="white-text" href="https://fkb.kg/#!">Понедельник - пятница с 16:30 - 21:00 ч., без перерыва;</a></li>
              <li><a class="white-text" href="https://fkb.kg/#!">Суббота - с 9:00 - 17:00 ч., без перерыва;</a></li>
              <li><a class="white-text" href="https://fkb.kg/#!">Воскресенье и праздничные дни - с 9:00 - 15:00 ч.</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="container">© 2008 ОАО «ФинансКредитБанк» Лицензия НБКР №047</div>
      </div>
    </footer>*/

    <footer className={cls.footer}>
      <WidthLimiter>
        <div className={cls.row}>
          <div className={cls.col}>
            <h5 className={cls.whiteText}>ОАО &quot;ФинансКредитБанк&quot;</h5>
            <p className={cls.text}>
              Наш адрес: г.Бишкек, ул.Абдрахманова 105
              <br />
              Тел.: (312)30-30-14, 30-35-10, 30-34-87, 30-34-12, 443-443
              <br />
              Моб., WhatsApp +996 (755) 443-443
              <br />
              E-mail: fkb@fkb.kg
            </p>
          </div>
          <div className={cls.col}>
            <h5 className={cls.whiteText}>Режим работы</h5>
            <ul>
              <li>
                <a className={cls.whiteText} href="https://fkb.kg/#!">
                  Понедельник-пятница с 8.30-16.30 ч., без перерыва;
                </a>
              </li>
              <li>
                <a className={cls.whiteText} href="https://fkb.kg/#!">
                  ВЕЧЕРНЯЯ КАССА тел. (312) 443 443 (доб. 183);
                </a>
              </li>
              <li>
                <a className={cls.whiteText} href="https://fkb.kg/#!">
                  Понедельник - пятница с 16:30 - 21:00 ч., без перерыва;
                </a>
              </li>
              <li>
                <a className={cls.whiteText} href="https://fkb.kg/#!">
                  Суббота - с 9:00 - 17:00 ч., без перерыва;
                </a>
              </li>
              <li>
                <a className={cls.whiteText} href="https://fkb.kg/#!">
                  Воскресенье и праздничные дни - с 9:00 - 15:00 ч.
                </a>
              </li>
            </ul>
          </div>
        </div>
      </WidthLimiter>
      <div className={cls.copyright}>
        <WidthLimiter>
          © 2008 ОАО «ФинансКредитБанк» Лицензия НБКР №047
        </WidthLimiter>
      </div>
    </footer>
  );
};
