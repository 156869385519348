import React from 'react';
import Select, { GroupBase, Props } from 'react-select';

export function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  return (
    <Select
      {...props}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        border: '1px solid #D3D4D8',
        height: 40,
        colors: {
          ...theme.colors,
          primary25: '#F2F2F2',
          primary: '#C62828'
        }
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          boxShadow: state.isFocused ? '0 0 1px #C62828' : '',
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          padding: '0 8px'
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: 'none'
        }),
        option: (baseStyles, { isSelected }) => ({
          ...baseStyles,
          backgroundColor: isSelected ? '#FFF1F1' : '',
          color: '#1E272E',
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          paddingInlineStart: '16px'
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          margin: 0
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px'
        })
      }}
    />
  );
}
