import React, { useEffect, useState } from 'react';
import { useFormDataStore } from '../../app/store/formDataStore';
import { CustomInput } from '../CustomInput';
import { formatCardNumber } from '../../app/helpers/formatCardNumber';
import { formatExpiryDate } from '../../app/helpers/formatExpiryDate';
import { checkLuhn } from '../../app/helpers/cardValidateLuhn';
import cls from './TransactionForm.module.scss';

export const CardFromRu = () => {
  const [
    cardFrom,
    setCardFrom,
    fromDate,
    fromCVC,
    fromName,
    setFromDate,
    setFromCVC,
    setFromName
  ] = useFormDataStore((state) => [
    state.cardFrom,
    state.setCardFrom,
    state.fromDate,
    state.fromCVC,
    state.fromName,
    state.setFromDate,
    state.setFromCVC,
    state.setFromName
  ]);
  const [cardValid, setCardValid] = useState(true);

  const changeCardFrom = (value: string) => {
    setCardValid(checkLuhn(value));
    setCardFrom(formatCardNumber(value));
  };
  const changeFromDate = (value: string) => {
    setFromDate(formatExpiryDate(value));
  };
  const changeFromCVC = (value: string) => {
    setFromCVC(value);
  };
  const changeFromName = (value: string) => {
    setFromName(value);
  };

  useEffect(() => {
    setCardFrom('');
    setFromName('');
  }, []);

  return (
    <div className={cls.cardUi}>
      <CustomInput
        value={cardFrom}
        onChange={changeCardFrom}
        closeBtn={true}
        placeholder={'Номер карты отправителя'}
        fullWidth={true}
        error={!cardValid}
      />
      <div className={cls.cardUiRow}>
        <CustomInput
          value={fromDate}
          onChange={changeFromDate}
          closeBtn={true}
          placeholder={'ММ/ГГ'}
          fullWidth={true}
          maxLength={5}
        />
        <CustomInput
          value={fromCVC}
          onChange={changeFromCVC}
          closeBtn={true}
          type="password"
          placeholder="CVC"
          fullWidth={true}
          maxLength={3}
        />
      </div>
      <CustomInput
        value={fromName}
        onChange={changeFromName}
        closeBtn={true}
        placeholder={'Фамилия и имя на карте'}
        fullWidth={true}
      />
    </div>
  );
};
